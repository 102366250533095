import React from 'react';
import MaterialUILayout from '../components/material-ui-layout';

const PageTemplate = () => {
  return (
    <MaterialUILayout>
      <h1>This is a page.</h1>
    </MaterialUILayout>
  );
};

export default PageTemplate;
